body{
  /* background-color: #047746; */
  color: azure;
  margin: 0 auto;
  width: 100%;
  background-image: url('231.png');
}
.top-div{
padding-top: 10rem;
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 0 auto;
}
.headings{
  flex: 3;
  font-size: 5rem;
  font-weight: 400;
}
.para{
  display: flex;
  flex: 1;
  line-height: 2rem;
  padding-top: 1rem;

}
.heading-sub{
  font-size: 1rem;
  font-weight: lighter;
  color: #EDF6EE
}
.circle{
  display: flex;
  justify-content: end;
  width: 95%;
}
.service{
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
}
.cardsdiv{
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0.5rem;
  margin: 0 auto;
}
.card1{
  flex: 1;
}
.card2{
  flex: 1;
}
:root {
  --surface-color: #fff;
  --curve: 40;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 4rem 5vw;
  padding: 0;
  list-style-type: none;
}

.card {
  position: relative;
  display: block;
  height: 100%;  
  border-radius: 5%;
  overflow: hidden;
  text-decoration: none;
}

.card__image {      
  width: 100%;
  height: 20rem;
}

.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;      
  border-radius: 5%;    
  background-color: var(--surface-color);      
  transform: translateY(100%);
  transition: .2s ease-in-out;
}

.card:hover .card__overlay {
  transform: translateY(0);
}

.card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 1em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;    
  background-color: var(--surface-color);
  transform: translateY(-100%);
  transition: .2s ease-in-out;
}

.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;      
  z-index: 1;
}

.card__arc path {
  fill: var(--surface-color);
  d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}       

.card:hover .card__header {
  transform: translateY(0);
}

.card__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;      
  border-radius: 50%;      
}

.card__title {
  font-size: 1em;
  margin: 0 0 .3em;
  color: #047746;
}

.card__tagline {
  display: block;
  margin: 1em 0;
  font-family: "MockFlowFont";  
  font-size: .8em; 
  color: #D7BDCA;  
}

.card__status {
  font-size: .8em;
  color: #D7BDCA;
}

.card__description {
  padding: 0 2em 2em;
  margin: 0;
  color: black;
  font-family: "MockFlowFont";   
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  opacity: 0.7;
}    
.About{
  display: flex;
  flex-direction: row;
  width: 90%;
  gap: 20rem;
  margin: 0 auto;
}
.about-stats{
  display: flex;
  flex: 2;
  gap: 4rem;


}
.stat{
  padding: 1rem;
}
.about-para{
  padding: 1rem;
  text-align: justify;
  flex: 1;
}
.heading-about{
  font-size: 4rem;
  width: 90%;
  margin: 0 auto;
  color: white;
}
.stat> h2{
font-weight: 1000;
font-size: 2.5rem;
}
.stat>p{
font-weight: 700;

}
.scrolling-strip {
background-color: #EDF6EE;
color: #047746;
overflow: hidden;
white-space: nowrap;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
padding: 1rem 0;
}

.scrolling-text {
display: inline-block;
white-space: nowrap;
animation: scroll 20s linear infinite;
}

.scrolling-text span {
padding: 0 1rem;
font-size: 1.5rem;
font-weight: bold;
}

@keyframes scroll {
0% {
  transform: translateX(100%);
}
100% {
  transform: translateX(-100%);
}
}
.containers {
max-width: 1000px;
margin: 0 auto;
padding: 20px;
}

.containers-heading {
font-family: "Roboto", sans-serif;
font-weight: 700;
font-style: normal;
font-size: 4rem;
}

.containers-para {
color: #edf0f6;
font-family: "Roboto", sans-serif;
font-weight: 700;
font-style: normal;
}

.form-container {
display: flex;
align-items: flex-start;
}

.form-container form {
font-family: "Roboto", sans-serif;
font-weight: 700;
font-style: normal;
font-size: 1rem;
width: 55%;
}
.form1{
  flex: 10;

}
.image1{
  flex: 1;
}

.form-container img {
border-radius: 10px;
}

h1 {
font-size: 36px;
color: white;
}

label {
display: block;
margin-bottom: 10px;
color: white;
}

input[type="text"], input[type="email"], textarea {
width: 100%;
padding: 8px;
margin-bottom: 8px;
border: 1px solid white;
border-radius: 4px;
background-color: transparent;
color: rgb(255, 255, 255);
box-sizing: border-box; /* Ensure padding is included in the width and height calculations */
}

input[type="text"], input[type="email"] {
height: 40px; /* Define a fixed height for single-line inputs */
}

input[type="text"]:focus, input[type="email"]:focus, textarea:focus {
background-color: rgba(89, 0, 255, 0.411); /* Lighten the background color on focus */
outline: none; /* Remove default outline on focus */
border-color: #a8ff00; /* Change border color on focus */
}

textarea {
height: 150px;
resize: none;
}

.submit-btn {
  font-weight: bold;
  border-radius: 1.5rem;
  border-width: 0rem;
  padding: 1rem 2rem 1rem 2rem;
  margin-top: 2rem;
}

.submit-btn:hover {
background-color: rgb(157, 157, 157); /* Slightly darker green on hover */
}
.img-contact{
height: 30rem;
width: 23rem;
align-items: center;
/* box-shadow: 0px 19px 26px 26px rgba(0,0,0,0.75); */
}
.img-company{
  height: 30rem;
width: 23rem;
width: 95%;
margin: 0 auto;
}
.footer {
background-color: #EDF6EE;
color: white;
padding: 20px;
text-align: center;
}

.footer a {
color: white;
text-decoration: none;
margin: 0 15px;
}
@media screen and (max-width: 1024px) {
.top-div {
  flex-direction: column;
  gap: 0rem;
  width: 100%;
}
.cardsdiv{
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}
.About{
  flex-direction: column;
  gap: 0rem;
}
.stat> h2{
  font-weight: 700;
  font-size: 2rem;
}
.stat>p{
  font-weight: 700;

}
.form-container{
  flex-direction: column;
  gap: 2rem;
  align-items: center;
} 
.about-stats{
  gap: 0rem;
}
.headings{
  font-size: 3rem;
}
.img-contact{
  box-shadow: none;
}
.footer{
  padding: 0px;
  padding-top: 1rem;
  text-align: left;
}
.form-container form {
  width: 100%;
}
.submit-btn{
  margin: 0 auto;
}
.scrolling-text{
animation: scroll 20s linear infinite;
}
.img-company{
  object-fit: contain;
}
}