body {
  background-color: rgb(26, 26, 26);
  width: 100%;
  scroll-behavior: smooth;
}
nav {
  z-index: 1;
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  color: #047746;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1.5rem;
  background-color: #EDF6EE;
  text-align: center;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.logo {
  width: 5rem;
  height: 5rem;
  padding-left: 2rem;
}

.nav-links {
  padding-right: 2rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.nav-links a {
  text-decoration: none;
  color: #047746;
  font-size: 1.5rem;
}

.hamburger {
  display: none;
  font-size: 2rem;
  cursor: pointer;
  padding-right: 2rem;
}

.nav-co {
  color: #047746;
  font-size: 0.9rem;
}
.main {
  display: flex;
  padding-top: 7rem;
}
.heading {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 10rem;
}
.main-img {
  display: flex;
  gap: 1rem;
  flex: 1;
  overflow: hidden;
  justify-content: center;
  margin-top: 5rem;
}
.mi-flex1 {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.mi-flex1 > img {
  height: 12rem;
  width: auto;
}
#col-1 {
  margin-top: 5rem;
}
/* #img-1 {
    position: relative;
  } */
.main-heading {
  font-size: 5rem;
  color: white;
  text-align: center;
  font-weight: bold;
}
.sub-heading {
  font-size: 1rem;
  color: white;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 1.5rem;
}
.but-head {
  width: 100%;
  text-align: center;
}
.but-heading {
  font-weight: bolder;
  border-radius: 1.5rem;
  border-width: 0rem;
  padding: 1rem 1rem 1rem 1rem;
  background-color: white;
  margin-top: 1rem;
  text-decoration: none;
  color: #047746;
}
.intro {
  display: flex;
}
.intro-text {
  flex: 7;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.intro-text-desc {
  font-size: 1.5rem;
  color: white;
  width: 80%;
  opacity: 0.7;
  margin: 0 auto;
}

.intro-text-heading {
  font-size: 2.5rem;
  color: white;
  font-weight: bold;
}

.intro-img {
  flex: 3;
}
.intro-image {
  margin-top: 4rem;
  height: 25rem;
  width: auto;
  border-top-right-radius: 3rem;
  border-bottom-right-radius: 3rem;
}
.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.container .glass {
  position: relative;
  width: max-content;
  height: 350px;
  background: linear-gradient(#fff2, transparent);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 25px 25px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  border-radius: 10px;
  margin: 0 -45px;
  backdrop-filter: blur(10px);
  transform: rotate(calc(var(--r) * 1deg));
}

.container:hover .glass {
  transform: rotate(0deg);
  margin: 3rem;
}
.glass > a > img {
  height: 350px;
}

.container .glass::before {
  content: attr(data-text);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  font-size: 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.container .glass img {
  fill: #fff;
}
.service-main {
  width: 90%;
  margin: 0 auto;
  background: linear-gradient(#fff2, transparent);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 25px 25px rgba(0, 0, 0, 0.25);
  border-radius: 5rem;
}
.service-heading {
  font-size: 3rem;
  color: white;
  font-weight: bold;
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  width: 90%;
  margin: 0 auto;

  border-top-left-radius: 5rem;
  border-top-right-radius: 5rem;
}
.services-desc {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  border-bottom-left-radius: 5rem;
  border-bottom-right-radius: 5rem;

  width: 90%;
  margin: 0 auto;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  color: white;
}
.service-exp {
  display: flex;
  width: 95%;
  margin: 0 auto;
}
#mobile-only {
  display: none;
}
#desktop-only {
  display: block;
}
.service-img {
  flex: 1;
  overflow: hidden;
  align-items: center;
  text-align: center;
}
.service-img > img {
  height: 20rem;
  width: auto;
  border-radius: 3rem;
  border-top-right-radius: 3rem;
  margin-top: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 25px 25px rgba(0, 0, 0, 0.25);
}
.service-text {
  flex: 1;
  text-align: center;
}
.service-text-heading {
  text-align: center;
  font-size: 2.5rem;
  color: white;
  font-weight: bold;
  padding-top: 2rem;
}
.service-text-desc {
  width: 95%;
  margin: 0 auto;
  font-size: 1.3rem;
  color: white;
  text-align: justify;
  padding-top: 1rem;
  opacity: 0.7;
}
.connect {
  margin-top: 2rem;
  justify-content: center;
  text-align: center;
}
.connect > button {
  border: solid 2px white;
  color: white;
  background-color: rgb(26, 26, 26);
  font-size: 1.5rem;
  font-weight: bold;
  width: 15rem;
  height: 4rem;
  border-radius: 1rem;
}
.contact {
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  border-left: none;
  border-right: none;
  width: 90%;
  margin: 0 auto;
  display: flex;
}

.contact1 {
  padding-left: 3rem;
  padding-right: 3rem;
  color: white;
  font-size: 3rem;
  font-weight: 600;
  flex: 2;
}

.contact2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.contact2 > button {
  font-weight: bolder;
  border-radius: 1.5rem;
  border-width: 0rem;
  padding: 1rem 2rem 1rem 2rem;
}

.footer {
  background-color: #EDF6EE;
  display: flex;
  width: 90%;
  margin: 0 auto;
}

.footer > div > div > h1 {
  color: white;

}
.footer-logo{
  height: 2rem;

}

@media screen and (max-width: 1024px) {
  .main {
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
  }
  .heading {
    margin-top: 5rem;
  }
  .main-heading {
    font-size: 3rem;
  }
  .but-head {
    margin-bottom: 2rem;
  }
  .main-img {
    flex: 1;
    margin: 0 auto;
    width: 80%;
  }
  .mi-flex1 {
    flex: 1;
    margin: 0 auto;
  }
  .mi-flex1 > img {
    height: 12rem;
    width: 75%;
    object-fit: cover;
    border-radius: 1rem;
  }
  .intro {
    margin-top: 2rem;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    overflow: visible;
  }
  .intro-img {
    height: 20rem;
  }
  .intro-image {
    height: 16rem;
    overflow: hidden;
    border-top-left-radius: 3rem;
    border-bottom-left-radius: 3rem;
    margin-top: 1rem;
  }
  .intro-text {
    flex: 4;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  .intro-text-heading {
    font-size: 2.5rem;
  }
  .intro-text-desc {
    font-size: 1.5rem;
  }
  .container {
    margin-top: 2rem;
  }
  .container .glass::before {
    font-size: 10px;
    text-align: center;
  }
  .container.scrolled .glass {
    transform: rotate(0deg);
    margin: 10px;
  }
  .container .glass {
    height: 250px;
    margin-top: 3rem;
  }
  .glass > a > img {
    height: 250px;
    width: auto;
  }
  .service-heading {
    font-size: 2rem;
    margin-top: 2rem;
  }
  .service-exp {
    display: flex;
    width: 95%;
    margin: 0 auto;
    flex-direction: column;
  }
  .service-img {
    overflow: hidden;
  }
  .service-img > img {
    height: 16rem;
    width: auto;
    border-radius: 3rem;
    border-top-right-radius: 3rem;
  }
  .service-text {
    flex: 1;
    text-align: center;
  }
  .service-text-heading {
    text-align: center;
    font-size: 1.5rem;
    color: white;
    font-weight: bold;
  }
  .service-text-desc {
    width: 95%;
    margin: 0 auto;
    font-size: 13px;
    color: white;
  }
  #mobile-only {
    display: block;
  }
  #desktop-only {
    display: none;
  }
  .contact {
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    margin-top: 2rem;
  }
}
@media screen and (max-width: 548px) {
  .container {
    width: 95%;
    margin-top: 2rem;
  }
  .main-img {
    width: 80%;
    margin: 0 auto;
  }
  .container.scrolled .glass {
    transform: rotate(0deg);
    margin: 0 5px;
  }
  .container .glass {
    height: 150px;
    margin-top: 2rem;
  }
  .mi-flex1 > img {
    height: 8rem;
    width: 90%;
  }
  .intro-text {
    flex: 4;
    margin-top: 1rem;
    font-size: 2rem;
  }
  .intro-text-heading {
    font-size: 1.5rem;
  }
  .intro-text-desc {
    font-size: 15px;
  }
  .intro-image {
    height: 12rem;
    margin-top: 1rem;
  }
  .glass > a > img {
    height: 150px;
    width: auto;
  }
  .service-exp {
    flex-direction: column;
    margin-top: 2rem;
  }
  #desktop-only {
    display: none;
  }
  #mobile-only {
    display: block;
  }
  .service-img > img {
    height: 12rem;
    width: auto;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
  }
  .connect > button {
    font-size: 1rem;
    font-weight: bold;
    width: 12rem;
    height: 3rem;
    border-radius: 1rem;
  }
}
