/* App.css */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating-circle-container {
  display: inline-block;
  animation: rotate 10s linear infinite;
}
