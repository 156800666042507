body {
    font-family: "Cinzel", serif;
    background-color: #047746;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

nav {
    z-index: 1;
    position: fixed;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    color: #047746;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1.5rem;
    background-color: #edf6ee;
    text-align: center;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

nav>img {
    width: 5rem;
    height: 3rem;
}
.logo {
    width: 5rem;
    height: 5rem;
    padding-left: 2rem;
}


.nav-links {
    padding-right: 2rem;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.nav-links a {
    text-decoration: none;
    color: #047746;
    font-size: 1.5rem;
}

.hamburger {
    display: none;
    font-size: 2rem;
    cursor: pointer;
}

.nav-co {
    color: white;
    font-size: 0.9rem;
}


.home-pg {
    padding-top: 10rem;
    display: flex;
    width: 95%;
    margin: 0 auto;

}


.home-pg1 {
    display: flex;
    flex-direction: column;
    font-size: 2.5rem;
    align-items: center;
    flex: 1;
    color: white;
}


.home-pg2 {
    display: flex;
    flex: 3;
    justify-content: center;
    font-size: 5rem;
    color: white;
}

.home-pg3 {
    display: flex;
    flex-direction: column;
    flex: 2;
    justify-content: center;
    font-size: 3rem;
    color: white;
}

.home-pg4 {
    border-color: white;
    border-width: 1.5rem;
    border-radius: 2rem;
    color: rgb(26, 26, 26);
}

.talk {
    width: 90%;
    margin: 0 auto;
    padding-top: 5rem;
    display: flex;
    gap: 1rem
}

.talk1 {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.talk1>button {
    font-weight: bolder;
    border-radius: 1.5rem;
    border-width: 0rem;
    padding: 1rem 2rem 1rem 2rem;
}

.talk2 {
    padding-top: 1rem;
    color: white;
    flex: 2;
    padding-right: 2rem;
}

.story {
    padding-top: 5rem;
    display: flex;
}

.story1 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex: 1;
}

.responsive-image {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover;
}

.story2 {
    justify-content: center;
    align-items: center;
    gap: 2rem;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.story2>button {
    cursor: pointer;
    font-size: 2.3rem;
    color: grey;
    border-width: 0rem;
    background-color: rgb(26, 26, 26);
}

.story3 {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    flex: 1;
    padding-right: 5rem;
}

.services {
    padding: 2rem;
    display: flex;
    gap: 1rem
}

.services1 {
    gap: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0rem 2rem 0rem;
    background-color: white;
    flex: 1;
    transform: rotate(-10deg);
}

.services2 {
    gap: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0rem 2rem 0rem;
    background-color: white;
    flex: 1;
    transform: rotate(10deg);
}

.services3 {
    gap: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0rem 2rem 0rem;
    background-color: white;
    flex: 1;
    transform: rotate(-10deg);
}

.services4 {
    gap: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0rem 2rem 0rem;
    background-color: white;
    flex: 1;
    transform: rotate(10deg);
}

.services5 {
    gap: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0rem 2rem 0rem;
    background-color: white;
    flex: 1;
    transform: rotate(10deg);
}

.services6 {
    gap: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0rem 2rem 0rem;
    background-color: white;
    flex: 1;
    transform: rotate(-10deg);
}

.services7 {
    gap: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0rem 2rem 0rem;
    background-color: white;
    flex: 1;
    transform: rotate(10deg);
}

.services8 {
    gap: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0rem 2rem 0rem;
    background-color: white;
    flex: 1;
    transform: rotate(-10deg);
}

.videos {
    padding-top: 8rem;
    display: flex;
}

.videos1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5rem;
    justify-content: center;
    flex: 1;
}

.videos2 {
    margin-top: -5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    gap: 5rem;
}

.small-video {
    height: 30rem;
    width: auto;
}

.grey {
    color: white;
    font-weight: lighter;
    font-size: 0.8rem;
}

.greys {
    display: flex;
    justify-content: space-between;
}

.case {
    padding-top: 5rem;
    padding-bottom: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.case>button {
    cursor: pointer;
    background-color: rgb(26, 26, 26);
    color: white;
    border-color: white;
    padding: 1rem 10rem 1rem 10rem;
    border-radius: 2rem;
    border-width: 0.1rem;
}

.contact {
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    border-left: none;
    border-right: none;
    width: 90%;
    margin: 0 auto;
    display: flex;
}

.contact1 {
    padding-left: 3rem;
    padding-right: 3rem;
    color: white;
    font-size: 3rem;
    font-weight: 600;
    flex: 2;
}

.contact2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.contact2>button {
    font-weight: bolder;
    border-radius: 1.5rem;
    border-width: 0rem;
    padding: 1rem 2rem 1rem 2rem;
    color: #047746;
}

.footer {
    background-color: white;
    display: flex;
    width: 100%;
    margin: 0 auto;
    color: #047746;

    justify-content: center;
}

.footer>div>div>h1,.footer>div>div>p {
    color: #047746;
}

@media (max-width: 512px) {
    .nav-links {
        display: none;
        flex-direction: column;
        padding-bottom: 2rem;
        gap: 2rem;
        position: absolute;
        top: 100px;
        left: 0;
        width: 100%;
        text-align: center;
        background-color: #edf6ee;
        
        padding-top: 1rem;
        text-align: center;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);

    }

    .nav-links.mobile {
        display: flex;
    }

    .hamburger {
        display: block;
    }
}

@media screen and (max-width: 1024px) {
    .home-pg {
        display: flex;
        flex-direction: column;

    }

    .home-pg1 {
        align-items: start;
    }

    .home-pg2 {
        padding-top: 2rem;
        flex: 1;
        font-size: 3rem;
    }

    .home-pg3 {
        padding-top: 2rem;
        flex: 1;
        font-size: 3rem;
    }

    .talk {
        display: flex;
        flex-direction: column-reverse;
    }

    .services {
        display: flex;
        flex-direction: column;
    }

    .videos {
        display: flex;
        flex-direction: column;
    }

    .videos2 {
        margin-top: 5rem;
    }

    .contact {
        display: flex;
        flex-direction: column;
        padding-bottom: 2rem;
    }

    .case>button {
        padding: 1rem 5rem 1rem 5rem;
    }

    .home-pg {
        width: 100%;
    }

    .story {
        display: flex;
        flex-direction: column;
    }

    .story2 {
        padding-top: 3rem;
    }

    .story3 {
        padding: 3rem 1rem 1rem 1rem;
    }
}